(function ($) {
  var cliniqueCanadaInterstitialV1 = {
  };

  Drupal.behaviors.cliniqueCanadaInterstitialV1 = {
    get: function (key) {
      return cliniqueCanadaInterstitialV1[key];
    },

    set: function (key, val) {
      cliniqueCanadaInterstitialV1[key] = val;
    },

    attach: function (context, settings) {
      $('html').once('cliniqueCanadaInterstitial', function () {
        if (!generic.cookie('CA_interstitial') && Drupal.settings.enable_interstitial) { // if the CA interstital is enabled & cookie NOT set...
          $('<div/>', {
            'id': 'interstitial-content-deck',
            css: { 'display': 'none' },
            'interstitial_loaded': 'loaded'
          })
            .appendTo('body')
            .load('/includes/content/interstitial.tmpl');
        }
      });
    }
  };
})(jQuery);
